@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
body{
  background-color: #F6F5F9;
  font-family: 'Open Sans', sans-serif !important;
}

.home,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #000;
  color: #ffffff;
}

/* Navbar CSS */
.navbar {
  background-color: rgb(56,66,89);
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px !important;

 
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #ffff;
}

.nav-menu {
  background-color: rgb(56,66,89);
  /* width: 250px;   */
  height: 100vh;
 flex-wrap: wrap;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 9999;
  
}

.nav-menu.active {
  left: 0;
  transition: 450ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #ffff;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: rgb(13,110,253);
  color: #fff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: rgb(56,66,89);
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
input::placeholder {
padding-left: 10px;
}

.companyName{
  text-decoration: none;
  color: #ffff;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  height: 100%; 
   display: flex;
  align-items: center;
  padding: 0 16px;
  margin-top: 20px;
  /* border-radius: 4px; */

}

 @media only screen and (max-width: 400px) {
  .companyName{
    font-size: 20px;
  }

} 


